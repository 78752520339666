<template>
  <a-layout-sider
    breakpoint="lg"
    collapsed-width="0"
  >
    <div class="logo">
      <a href="/dashboard/overview">
        <img
          style="width: 150px"
          src="../assets/logo-exim.jpg"
        />
      </a>
    </div>
    <a-menu :default-selected-keys="navActive" mode="inline">
      <a-menu-item key="dashboard/overview">
        <router-link to="/dashboard/overview">
          <a-icon type="pie-chart" />
          <span>Dashboard</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard/legales">
        <router-link to="/dashboard/legales">
          <a-icon type="file" />
          <span>Legales</span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    navActive() {
      const path = location.pathname.substring(1);
      if (/^dashboard\/[a-zA-Z-]+/.test(path)) {
        return [path];
      }
      return [];
    },
  }
};
</script>

<style lang="scss">
.ant-layout-sider {
  background: $white;

  .logo {
    padding: 1rem;
  }

  .ant-menu {
    .ant-menu-item {
      background: none;

      &-selected {
        background: $secondary-color;

        a {
          color: $white;

          &:hover {
            color: $white;
          }
        }

        &:after {
          border-color: #132d2f;
        }
      }

      &-active {
        a {
          color: $secondary-color;
        }
      }
    }
  }

  .ant-layout-sider-trigger {
    background: $secondary-color;
  }
}
</style>
