<template>
  <a-layout-header style="background: #fff;">
    <a-row type="flex" justify="end">
      <a-col>
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            Welcome, <strong>{{ userProfile.firstname }}</strong><a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="logout"><a-icon type="logout" /> Logout</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TopBar',
  computed: {
    ...mapGetters({
      userProfile: "getUser"
    })
  },
  methods: {
    logout(e) {
      e.preventDefault()
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="less">
.topbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .5rem 2rem;

  ul {
    margin: 0;

    li {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      button {
        background: none;
        border: 0;
        cursor: pointer;
        line-height: initial;
        span {
          margin-left: .5rem;
        }
      }
    }
  }
}
</style>
