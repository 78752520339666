<template>
  <a-layout style="min-height: 100vh">
    <helmet-provider>
      <helmet>
        <title>eXIM | Dashboard</title>
        <link rel="icon" type="image/png" href="../assets/logo-exim.jpg" />
      </helmet>
    </helmet-provider>
    <Sidebar />
    <a-layout>
      <TopBar />
      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { Helmet, HelmetProvider } from "@jnields/vue-helmet";

export default {
  name: "Dashboard",
  components: {
    Helmet,
    HelmetProvider,
    Sidebar,
    TopBar,
  },
};
</script>

<style lang="less">
.ant-layout-content {
  background: white;
  margin: 1rem;
  padding: 1rem;
}
</style>